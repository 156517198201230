import { LayoutProps } from '@/components/Layout/Layout';

export type SocialBodyType = Array<Record<string, any>>;
const generateHeadData = (socialBody: SocialBodyType): LayoutProps =>
  socialBody.map(({ primary: { description, image, title } }) => ({
    title: title.text,
    description: description.text,
    ogTitle: title.text,
    ogDescription: description.text,
    ogImageUrl: image.url,
  }))[0];

export default generateHeadData;
