import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { SliceZone } from '@prismicio/react';
import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Layout from '@/components/Layout';
import PricingSwitcher from '@/components/PrcingSwitcher/PrcingSwitcher';
import Section from '@/components/Section';
import ButtonLink from '@/components/ButtonLink';

import generateHeadData from '@/utils/generateHeadData';
import { Box } from '@mui/system';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import usePricingContext from '@/hooks/usePricingContext';
import components from '../slices';

const PricingTemplage = ({ data, pageContext }) => {
  const {
    social_body: socialBody,
    pricing_page_headline: pricingPageHeadline,
    pricing_page_description: pricingPageDescription,
    pricing_page_main_cta_link: mainCtaLink,
    pricing_page_main_cta_label: mainCtaLable,
    pricing_page_main_cta_mixpanel_event: mainCtaMixpanelEvent,
    monthly_pricing_period_label: monthlyLabel,
    yearly_pricing_period_label: yearlyLabel,
    additional_pricing_title: additionalTitle,
    default_pricing_monthly: defaultPricingMonthly,
    turn_off_pricing_switcher_usd: turnOffPricingSwitcherUsd,
    turn_off_pricing_switcher_jpy: turnOffPricingSwitcherJpy,
    turn_off_pricing_switcher_twd: turnOffPricingSwitcherTwd,
    body,
    body1,
    body3,
    body4,
  } = data.prismicPricingPage.data;

  // html head data
  const headData = useMemo(() => generateHeadData(socialBody), [socialBody]);

  const { setType } = usePricingContext();

  React.useEffect(() => {
    if (defaultPricingMonthly) {
      setType('monthly');
    } else {
      setType('yearly');
    }
  }, []);

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <Section
        sx={{
          px: { xs: 3, sm: 4 },
          pb: 7.25,
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            width: 1,
            flexDirection: `column`,
            alignItems: `center`,
            gap: 4,
            display: `flex`,
            mx: 'auto',
          }}
        >
          {pricingPageHeadline.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="pricingHeader"
              variant={type}
            >
              {text}
            </CustomTypography>
          ))}
          {pricingPageDescription.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="subtitleLarge"
              variant={type}
            >
              {text}
            </CustomTypography>
          ))}
          {mainCtaLable && (
            <ButtonLink
              to={mainCtaLink.url}
              target={mainCtaLink.target}
              dataEventAction={mainCtaMixpanelEvent}
            >
              {mainCtaLable}
            </ButtonLink>
          )}
          <PricingSwitcher
            monthlyLabel={monthlyLabel}
            yearlyLabel={yearlyLabel}
            turnOffPricingSwitcherUsd={turnOffPricingSwitcherUsd}
            turnOffPricingSwitcherJpy={turnOffPricingSwitcherJpy}
            turnOffPricingSwitcherTwd={turnOffPricingSwitcherTwd}
          />
        </Box>
      </Section>
      <Section
        sx={{
          px: { xs: 3, sm: 4 },
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            width: 1,
            mx: 'auto',
            display: `flex`,
            alignItems: `center`,
            justifyContent: { xs: `center`, xl: `space-between` },
            flexDirection: {
              xs: `column`,
              md: body.length > 3 ? `column` : `row`,
              lg: `row`,
            },
            gap: { xs: 16, md: 2, lg: 4 },
          }}
        >
          <SliceZone
            slices={body}
            components={components}
            context={{ length: body.length }}
          />
        </Box>
      </Section>
      <Section sx={{ pt: 1.5, px: { xs: 3, sm: 4 }, pb: 0 }}>
        <Box
          sx={{
            maxWidth: 1200,
            width: 1,
            mx: 'auto',
            display: `flex`,
            alignItems: { xs: `center`, md: `start` },
            justifyContent: `center`,
            flexDirection: { xs: `column`, md: `row` },
            gap: 3,
          }}
        >
          <SliceZone slices={body1} components={components} />
        </Box>
      </Section>
      <Section sx={{ px: { xs: 3, sm: 4 } }}>
        <Box sx={{ maxWidth: 'lg', width: 1, mx: 'auto' }}>
          {additionalTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading3"
              sx={{ width: `100%`, mb: 4 }}
            >
              {text}
            </CustomTypography>
          ))}
          <Grid
            container
            spacing={{ xs: 0, md: 1.5 }}
            rowGap={{ xs: 7.5, sm: 9, md: 7.5 }}
            direction={{ xs: 'column', md: 'row' }}
          >
            <SliceZone slices={body3} components={components} />
          </Grid>
        </Box>
      </Section>
      <SliceZone slices={body4} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query pricingQuery($lang: String) {
    prismicPricingPage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        social_body {
          ... on PrismicPricingPageDataSocialBodyGeneralCard {
            primary {
              title {
                text
              }
              description {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicPricingPageDataSocialBodyTwitterCard {
            primary {
              title {
                text
              }
              description {
                text
              }
              image {
                url
              }
            }
          }
        }
        pricing_page_headline {
          richText
        }
        pricing_page_description {
          richText
        }
        monthly_pricing_period_label
        yearly_pricing_period_label
        pricing_page_main_cta_link {
          url
          target
        }
        pricing_page_main_cta_label
        pricing_page_main_cta_mixpanel_event
        additional_pricing_title {
          richText
        }
        default_pricing_monthly
        turn_off_pricing_switcher_usd
        turn_off_pricing_switcher_jpy
        turn_off_pricing_switcher_twd
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...PrimaryPricingTier
        }
        body1 {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...SecondaryPricingTier
        }
        body3 {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...SingleTable1
          ...SingleTable
        }
        body4 {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...PricingBanner
          ...PricingPagePipedriveForm
        }
      }
    }
  }
`;

export default PricingTemplage;
