import React from 'react';
import { Box } from '@mui/material';
import Typography from '@/components/Typography';
import usePricingContext from '@/hooks/usePricingContext';

type PricingSwitcherProps = {
  monthlyLabel: string;
  yearlyLabel: string;
  turnOffPricingSwitcherUsd: boolean;
  turnOffPricingSwitcherJpy: boolean;
  turnOffPricingSwitcherTwd: boolean;
};
const PricingSwitcher = ({
  monthlyLabel,
  yearlyLabel,
  turnOffPricingSwitcherUsd,
  turnOffPricingSwitcherJpy,
  turnOffPricingSwitcherTwd,
}: PricingSwitcherProps): JSX.Element => {
  const { type: pricingType, setType, priceLoc } = usePricingContext();

  if (priceLoc === `US` && turnOffPricingSwitcherUsd) {
    return <></>;
  }

  if (priceLoc === `JP` && turnOffPricingSwitcherJpy) {
    return <></>;
  }

  if (priceLoc === `TW` && turnOffPricingSwitcherTwd) {
    return <></>;
  }

  return (
    <Box sx={{ alignSelf: `flex-end`, mt: { md: -5.25 } }}>
      <Box sx={{ display: `flex`, alignSelf: `flex-end` }}>
        <Box
          sx={{
            p: 1,
            cursor: `pointer`,
            ...(pricingType === `monthly` && {
              borderBottom: `2px solid #0A0101`,
            }),
          }}
          onClick={() => setType(`monthly`)}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `12px`,
              lineHeight: 1,
              ...(pricingType !== `monthly` && { color: `#858080` }),
            }}
          >
            {monthlyLabel}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            cursor: `pointer`,
            ...(pricingType !== `monthly` && {
              borderBottom: `2px solid #0A0101`,
            }),
          }}
          onClick={() => setType(`yearly`)}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: `12px`,
              lineHeight: 1,
              ...(pricingType === `monthly` && { color: `#858080` }),
            }}
          >
            {yearlyLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingSwitcher;
